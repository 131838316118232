import React, { useEffect } from 'react'

import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"
import * as styles from './404.module.css'

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  useEffect(() => {
    try {
      if (window) {
        const adsbygoogle = window.adsbygoogle || [];
        adsbygoogle.push({});
      }
    } catch (e) {
      console.error(e);
    }
  }, [])

  return (
    browser && (
      <Layout>
        <SEO title="Sivua ei löytynyt" />
        <h1>Voi ei, sivua ei löytynyt!</h1>
        <p>Huomaathan, että osa ravintoloista julkaisee seuraavan viikon ruokalistan myöhemmin viikolla.</p>
        <p>Voit myös lähettää meille <Link to="/palaute">palautetta</Link> jos jokin oleellinen sivu on hukassa.</p>
        <h1>╯︿╰</h1>
        <div id="adbanner">
          <ins className={[styles.adBanner, 'adsbygoogle'].join(' ')}
            data-ad-client="ca-pub-2668838512868610"
            data-ad-slot="2714921034"
            data-ad-format="horizontal,auto"
            data-full-width-responsive="true"></ins>
        </div>
      </Layout>
    )
  );
};

export default NotFoundPage
